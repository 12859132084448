import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import RichTextConverter from '../atoms/RichTextConverter';
import styled from 'styled-components';
import Share from '../../assets/share.inline.svg';
import Create from '../../assets/create.inline.svg';
import Embed from '../../assets/embed.inline.svg';
import Implement from '../../assets/implement.inline.svg';


const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3rem;
  color: ${props => props.theme.colours.cream};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ValueHolder = styled.div`
  display: grid;
  grid-template-columns: 5rem auto;
  align-items: center;
  gap: 1rem;
  justify-items: center;
  ${props => props.theme.fluidType(-0.2)};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    ${props => props.theme.fluidType(-0.5)};
    grid-template-columns: 1fr 1.8fr;
    gap: 1rem;
  }

  svg {
    height: auto;
    width: 100%;
  }
`

export default function Values() {
    return (
        <StaticQuery
            query={graphql`
                query ValueQuery {
                    contentfulGlobalSettings {
                          shareText {
                            raw
                          }
                          embedText {
                            raw
                          }
                          create {
                            raw
                          }
                          implement {
                            raw
                          }
                    }
                }
            `}
            render={data => (
                <Holder>
                    <ValueHolder>
                        <Share/>
                        <RichTextConverter content={data.contentfulGlobalSettings.shareText}/>
                    </ValueHolder>
                    <ValueHolder>
                        <Embed/>
                        <RichTextConverter content={data.contentfulGlobalSettings.embedText}/>
                    </ValueHolder>
                    <ValueHolder>
                        <Create/>
                        <RichTextConverter content={data.contentfulGlobalSettings.create}/>
                    </ValueHolder>
                    <ValueHolder>
                        <Implement/>
                        <RichTextConverter content={data.contentfulGlobalSettings.implement}/>
                    </ValueHolder>
                </Holder>
            )}
        />
    )
}
