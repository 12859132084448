import React from 'react';
import SEO from '../components/molecules/SEO';
import Container from '../components/atoms/Container';
import styled from 'styled-components';
import Card from '../components/molecules/Card';
import CardTextOverlay from '../components/molecules/CardTextOverlay';
import CardExternalService from '../components/molecules/CardExternalService';
import PageHolder from '../components/organisms/PageHolder';
import {graphql} from 'gatsby';
import MarkdownConverter from '../components/atoms/MarkdownConverter';
import RichTextConverter from '../components/atoms/RichTextConverter';
import Values from "../components/organisms/Values";
import WeirdImageGrid from "../components/organisms/WeirdImageGrid";

const HeroText = styled.div`
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 2rem;
  line-height: 1.1;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    height: 50vh;
  }

  .hero-text {
    ${props => props.theme.fluidType(3.5)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(2.9)};
    }
  }
`;

const Text = styled.div`
  margin: ${props => props.padding ? '8rem 0' : '0'};
  color: ${props => props.theme.colours[props.colour]};
  ${props => props.headline ? props.theme.fluidType(2) : props.theme.fluidType(1)};
  font-family: 'Eliza', Helvetica, Arial, sans-serif;
  font-weight: 500;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    ${props => props.theme.fluidType(2.5)};
    margin: ${props => props.padding ? '10rem 0' : '0'};
    display: grid;
    grid-template-columns: ${props => props.indent ? '1fr 9fr 1fr' : '12fr 1fr'};
    grid-template-columns: ${props => props.leftCenter ? '1fr 9fr 1.5fr' : ''};
  }

  .contact-grid {
    grid-template-columns: 1fr 9fr 1.5fr;
  }

  .markdown, .rich-text-holder {
    grid-column: ${props => props.indent ? '2/3' : '1/2'};

    p {
      line-height: 1.1;
    }
  }

  a {
    color: ${props => props.theme.colours.yellow};
  }
`;

const Hero = styled.div`
  margin: ${props => props.padding ? '8rem 0' : '0'};
  color: ${props => props.theme.colours[props.colour]};
  ${props => props.theme.fluidType(3.5)};
  font-family: 'Eliza', Helvetica, Arial, sans-serif;
  font-weight: 500;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    ${props => props.theme.fluidType(3.1)};
    margin: ${props => props.padding ? '10rem 0' : '0'};
    display: grid;
    grid-template-columns: ${props => props.indent ? '1fr 9fr 1fr' : '12fr 1fr'};
    grid-template-columns: ${props => props.leftCenter ? '1fr 9fr 1.5fr' : ''};
  }

  .markdown, .rich-text-holder {
    grid-column: ${props => props.indent ? '2/3' : '1/2'};

    p {
      line-height: 1.1;
    }
  }
`;

const ExternalLinksHolder = styled.div`
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }
`;

function IndexPage({data}) {
    const content = data.contentfulGlobalSettings;
    return (
        <PageHolder bg="maroon" fg="cream">
            <SEO title="Home"/>
            <Container>
                <Hero colour="cream">
                    <MarkdownConverter content={content.frontpageText1.frontpageText1}/>
                </Hero>

                <HeroText>
                    <p className="h2 hero-text">Creating your common ground</p>
                </HeroText>

                <Card
                    aspectRatio={16 / 9}
                    post={content.featuredPost}
                    indent={true}
                    arrow={false}
                    colour="cream"
                />

                <Text padding={true} indent leftCenter colour="cream">
                    <MarkdownConverter content={content.frontpageText15.frontpageText15}/>
                </Text>

                <WeirdImageGrid>
                    <CardTextOverlay image={content.featuredProject.featuredImage}
                                     description={content.featuredProject.excerpt.excerpt}
                                     title={content.featuredProject.title}
                                     link={'/project/' + content.featuredProject.slug}
                                     colour="orange"/>
                    <CardTextOverlay image={content.featuredJournal.featuredImage}
                                     description={content.featuredJournal.excerpt.excerpt}
                                     title={content.featuredJournal.title}
                                     link={'/journal/' + content.featuredJournal.slug} colour="peach"/>
                    <CardTextOverlay image={content.papersImage}
                                     description={content.filesCaption}
                                     title="Files" link="/files" colour="pink"/>
                    <CardTextOverlay image={content.ttmImage}
                                     description={content.ttmCaption}
                                     title="TTM" link="/ttm" colour="yellow">
                    </CardTextOverlay>
                </WeirdImageGrid>

                <Text padding={true} indent leftCenter colour="cream">
                    <MarkdownConverter content={content.frontpageText2.frontpageText2}/>
                </Text>
                <Values/>
                <Text padding={true} indent leftCenter colour="cream">
                    <MarkdownConverter content={content.frontpageText25.frontpageText25}/>
                </Text>
            </Container>

            <Container>
                <h2 className="h6-smaller eliza-mono">Visit our other initiatives that help us deliver impactful outcomes:</h2>
                <ExternalLinksHolder>
                    <CardExternalService image={content.externalLinkImage__1} href={content.externalLinkHref__1}
                                         title={content.externalLinkTitle__1}/>
                    <CardExternalService image={content.externalLinkImage__2} href={content.externalLinkHref__2}
                                         title={content.externalLinkTitle__2}/>
                </ExternalLinksHolder>
            </Container>

            <Container>
                <Text padding={true} colour="cream" indent leftCenter>
                    <RichTextConverter content={content.frontpageText3}/>
                </Text>
            </Container>
        </PageHolder>
    )
};

export default IndexPage;

export const pageQuery = graphql`
    query {
        contentfulGlobalSettings(title: {eq: "Global Settings"}) {
            featuredPost {
                slug
                title
                excerpt {
                    excerpt
                }
                sys {
                    contentType {
                        sys {
                            id
                        }
                    }
                }
                featuredImage {
                    gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                    )
                }
            }
            featuredProject {
                slug
                title
                excerpt {
                    excerpt
                }
                sys {
                    contentType {
                        sys {
                            id
                        }
                    }
                }
                featuredImage {
                    gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                    )
                }
            }
            featuredJournal {
                slug
                title
                excerpt {
                    excerpt
                }
                sys {
                    contentType {
                        sys {
                            id
                        }
                    }
                }
                featuredImage {
                    gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                    )
                }
            }
            papersImage {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            filesCaption
            ttmImage {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            ttmCaption
            externalLinkImage__1 {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            externalLinkTitle__1
            externalLinkHref__1
            externalLinkImage__2 {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            externalLinkTitle__2
            externalLinkHref__2
            frontpageText1 {
                frontpageText1
            }
            frontpageText15 {
                frontpageText15
            }
            frontpageText2 {
                frontpageText2
            }
            frontpageText25 {
                frontpageText25
            }
            frontpageText3 {
                raw
            }
        }
    }
`;
