import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DiagonalArrow from '../../assets/diagonal-arrow.inline.svg';
import {GatsbyImage} from 'gatsby-plugin-image';


const Holder = styled.div`
  margin-bottom: 2rem;
`;

const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr 1rem;
  grid-gap: 1rem;
  align-items: center;
  margin-top: 0.5rem;

  .h6 {
    margin: 0;
    font-family: 'ABCWhyteInktrap', Helvetica, Arial, sans-serif;
  }

  svg {
    height: 0.7rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      height: unset;
    }
  }
`;

function CardExternalService({title, href, image}) {
    return (
        <Holder>
            <a href={href} target="_blank" rel="noopener noreferrer">
                <GatsbyImage
                    alt={title}
                    layout="fullWidth"
                    image={image.gatsbyImageData}/>
                <Text>
                    <h2 className="h6">{title}</h2>
                    <DiagonalArrow/>
                </Text>
            </a>
        </Holder>
    )
}

CardExternalService.propTypes = {
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
};

export default CardExternalService;
